body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
  }
  
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
  }
  
  .custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #516e86;
    font-weight: 500;
  }
  
  .custom-styles .ReactInputVerificationCode__item,
  .custom-styles .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
  }
  
  .custom-styles .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #ebebeb;
    transition: background-color 0.2s ease-out;
  }
  
  .custom-styles .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
  }
  