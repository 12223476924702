.rbc-btn-group button,
.rbc-event-content,
.rbc-toolbar-label, 
.rbc-header span {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.rbc-toolbar {
    display: none !important;
}

.rbc-btn-group {
    background-color: #fff;
    border-radius: 5px;
    display: none !important;
}

.rbc-time-view {
    border-radius: 5px;
}

.rbc-agenda-view {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.rbc-agenda-table {
    border-radius: 5px !important;
}

.rbc-day-bg.rbc-today {
    background-color: #717171c4 !important;
}
.rbc-today {
    color: #000;
    background-color: #eaf6ff2e;
}

.rbc-event-label {
    background-color: #fff;
    color: #000;
    border-radius: 2px;
}

.rbc-event {
    background: rgba(0,0,0,0) 
}

.rbc-time-gutter .rbc-timeslot-group {
    background-color: #fff;
    color: #000;
    border-radius: 2px;
}

.rbc-month-view {
    border-radius: 5px;
}

@media only screen and (max-width: 750px)  {
    .rbc-toolbar {
        flex-direction: column;
    }

    .rbc-btn-group {
        margin: 5px;
    }
}